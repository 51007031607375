<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <div class="unifiSettingsHeader">
          <div class="unifiSettingsHeader__left">
            <div class="unifiSettingsHeader__title"> Additional Field Categories </div>
          </div>
          <div class="unifiSettingsHeader__right">
            <div class="appActionBarFilters py-1">
              <div class="appActionBarFilters">
                <button v-if="selectedTab == 'additionalField'" @click="addFieldModal=true" class="jazeButton jazeButton--primary">
                  <span class="jaze-icon--plus"></span> &nbsp;Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
          <li @click="selectedTab = 'additionalField'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'additionalField'}">
            <span>Additional Fields</span>
          </li>
          <li @click="selectedTab = 'additionalFieldRule'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'additionalFieldRule'}">
            <span>Additional Field Rules</span>
          </li>
          <li @click="selectedTab = 'additionalFieldMapping'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'additionalFieldMapping'}">
            <span>Additional Field Mapping</span>
          </li>
        </ul>
        <div v-show="selectedTab == 'additionalField'">
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection" v-if="additionalFieldsData.metaFieldNameValues!= undefined && Object.keys(additionalFieldsData.metaFieldNameValues).length != 0">
              <div v-for="category in additionalFieldsData.categories" :key="category">
                <div v-if="additionalFieldsData.metaFieldNameValues[category].length!=0">
                  <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone">
                    {{category}}
                  </div>
                  <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                    <thead>
                      <tr>
                        <th> No. </th>
                        <th> Name </th>
                        <th> Type </th>
                        <th> Disabled </th>
                        <th> Mandatory </th>
                        <th> Default Value </th>
                        <th> </th>
                        <th> Actions </th>
                      </tr>
                    </thead>
                    <tbody class="jazeTableBody">
                      <tr v-if="additionalFieldsData.metaFieldNameValues[category].length !=0" v-for="(metaFieldNameValue,index) in additionalFieldsData.metaFieldNameValues[category]" :key="metaFieldNameValue.MetaFieldName.id">
                        <td>
                          <span> {{index+1}} </span>
                        </td>
                        <td>
                          <span v-if="additionalFieldsData.visible == 'visible'">
                            <div v-if="!editNameShow[index]">
                              <span> {{editName[index]}} </span>
                              <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleNameTrue(index)">
                                <span class="button__icon icon jaze-icon--pencil"></span>
                              </button>
                            </div>
                            <div v-if="editNameShow[index]">
                              <div class="appForm appForm--cozy">
                                <input class='width-full' type="text" v-model="editName[index]">
                              </div>
                              <div class="jazeButtonGroup col--sm3 jazeButtonGroup--joined">
                                <button title="Save" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="saveNamefn(index,metaFieldNameValue.MetaFieldName)">
                                  <span class="button__icon icon jaze-icon--select-check"></span>
                                </button>
                                <button title="Cancel" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleNameFalse(index)">
                                  <span class="button__icon icon jaze-icon--x"></span>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span v-else>
                            {{editName[index]}}
                          </span>
                        </td>
                        <td>
                          <span v-if="metaFieldNameValue.MetaFieldName.type == 'string'"> String </span>
                          <span v-else-if="metaFieldNameValue.MetaFieldName.type == 'date'"> Date </span>
                          <span v-else> Drop Down </span>
                        </td>
                        <td>
                          <span v-if="additionalFieldsData.visible == 'visible'">
                            <div v-if="!editDisabledShow[index]">
                              <span> {{editDisabledConvert[index]}} </span>
                              <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleDisabledTrue(index)">
                                <span class="button__icon icon jaze-icon--pencil"></span>
                              </button>
                            </div>
                            <div v-if="editDisabledShow[index]">
                              <div class="appForm appForm--cozy">
                                <select class='width-full' v-model="editDisabled[index]">
                                  <option value="yes"> Yes </option>
                                  <option value="no"> No </option>
                                </select>
                              </div>
                              <div class="jazeButtonGroup col--sm3 jazeButtonGroup--joined">
                                <button title="Save" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="saveDisabledfn(index,metaFieldNameValue.MetaFieldName)">
                                  <span class="button__icon icon jaze-icon--select-check"></span>
                                </button>
                                <button title="Cancel" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleDisabledFalse(index)">
                                  <span class="button__icon icon jaze-icon--x"></span>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span v-else> {{editDisabledConvert[index]}} </span>
                        </td>
                        <td>
                          <span v-if="additionalFieldsData.visible == 'visible'">
                            <div v-if="!editMandatoryShow[index]">
                              <span> {{editMandatoryConvert[index]}} </span>
                              <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleMandatoryTrue(index)">
                                <span class="button__icon icon jaze-icon--pencil"></span>
                              </button>
                            </div>
                            <div v-if="editMandatoryShow[index]">
                              <div class="appForm appForm--cozy">
                                <select class='width-full' v-model="editMandatory[index]">
                                  <option value="yes"> Yes </option>
                                  <option value="no"> No </option>
                                </select>
                              </div>
                              <div class="jazeButtonGroup col--sm3 jazeButtonGroup--joined">
                                <button title="Save" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="saveMandatoryfn(index,metaFieldNameValue.MetaFieldName)">
                                  <span class="button__icon icon jaze-icon--select-check"></span>
                                </button>
                                <button title="Cancel" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleMandatoryFalse(index)">
                                  <span class="button__icon icon jaze-icon--x"></span>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span v-else> {{editMandatoryConvert[index]}} </span>
                        </td>
                        <td>
                          <div v-if="additionalFieldsData.visible == 'visible'">
                            <button v-if="metaFieldNameValue.MetaFieldName.type == 'drop_down'" class="jazeButton jazeButton--link jazeButton--noWrap mb-1" type="button" @click="addOption(metaFieldNameValue.MetaFieldName.id)">
                              <div> <span class="jaze-icon--plus"></span> ADD </div>
                            </button>
                            <div v-if="metaFieldNameValue.MetaFieldName.type == 'drop_down' && metaFieldNameValue.MetaFieldName.value!=null" v-for="(metaValue, key, valueIndex) in editDefaultVal[index]" :key="metaValue">
                              <div v-if="!editDefaultValShow[index][valueIndex]">
                                <span> {{editDefaultDropdown[index][valueIndex]}} </span>
                                <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap" @click="toggleValueTrue(index, metaFieldNameValue.MetaFieldName, valueIndex)">
                                  <span class="button__icon icon jaze-icon--pencil"></span>
                                </button>
                                <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap" @click="deleteDropdownVal(metaFieldNameValue.MetaFieldName, key)">
                                  <span class="button__icon icon jaze-icon--trashcan"></span>
                                </button>
                              </div>
                              <div v-if="editDefaultValShow[index][valueIndex]">
                                <div class="appForm appForm--cozy">
                                  <input class='width-full' type="text" v-model="editDefaultDropdown[index][valueIndex]">
                                </div>
                                <div class="jazeButtonGroup col--sm3 jazeButtonGroup--joined">
                                  <button title="Save" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="saveValuefn(index, metaFieldNameValue.MetaFieldName, valueIndex, key)">
                                    <span class="button__icon icon jaze-icon--select-check"></span>
                                  </button>
                                  <button title="Cancel" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleValueFalse(index, metaFieldNameValue.MetaFieldName, valueIndex)">
                                    <span class="button__icon icon jaze-icon--x"></span>
                                  </button>
                                </div>
                                <br>
                              </div>
                            </div>
                            <div v-if="metaFieldNameValue.MetaFieldName.type == 'string' || metaFieldNameValue.MetaFieldName.type == 'date'">
                              <div v-if="!editDefaultValShow[index]">
                                <span> {{editDefaultVal[index]}} </span>
                                <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleValueTrue(index, metaFieldNameValue.MetaFieldName)">
                                  <span class="button__icon icon jaze-icon--pencil"></span>
                                </button>
                              </div>
                              <div v-if="editDefaultValShow[index]">
                                <div class="appForm appForm--cozy">
                                  <input class='width-full' type="text" v-model="editDefaultVal[index]">
                                </div>
                                <div class="jazeButtonGroup col--sm3 jazeButtonGroup--joined">
                                  <button title="Save" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="saveValuefn(index,metaFieldNameValue.MetaFieldName)">
                                    <span class="button__icon icon jaze-icon--select-check"></span>
                                  </button>
                                  <button title="Cancel" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="toggleValueFalse(index,metaFieldNameValue.MetaFieldName)">
                                    <span class="button__icon icon jaze-icon--x"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="metaFieldNameValue.MetaFieldName.type == 'drop_down' && metaFieldNameValue.MetaFieldName.value!=null" v-for="value in editDefaultVal[index]">
                              {{value}}
                            </div>
                            <div v-if="metaFieldNameValue.MetaFieldName.type == 'string' || metaFieldNameValue.MetaFieldName.type == 'date'">
                              {{editDefaultVal[index]}}
                            </div>
                          </div>
                        </td>
                        <td> </td>
                        <td>
                          <div class="">
                            <button class="jazeButton jazeButton--link jazeButton--noWrap" v-if="index!=0" type="button" @click="moveFieldUp(metaFieldNameValue)">
                              <span class="button__icon icon jaze-icon--arrow-up-4"></span><span class="button__label">Move up</span>
                            </button><br>
                            <button class="jazeButton jazeButton--link jazeButton--noWrap" v-if="index!=additionalFieldsData.metaFieldNameValues[category].length-1" type="button" @click="moveFieldDown(metaFieldNameValue)">
                              <span class="button__icon icon jaze-icon--arrow-down-4"></span><span class="button__label">Move down</span>
                            </button><br>
                            <button class="jazeButton jazeButton--link jazeButton--noWrap" type="button" @click="removeMetaData(metaFieldNameValue)">
                              <span class="button__icon icon  jaze-icon--x"></span><span class="button__label">Remove</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div v-show="selectedTab == 'additionalFieldRule'">
          <AdditionalFieldRules :additionalFields="additionalFieldsData.metaFieldNameValues" v-if="selectedTab == 'additionalFieldRule'"></AdditionalFieldRules>
        </div>
        <div v-show="selectedTab == 'additionalFieldMapping'">
          <AdditionalFieldMapping :additionalFields="additionalFieldsData.metaFieldNameValues.User" :additionalFieldMappingId="additionalFieldsData.additionalFieldMappingId" v-if="selectedTab == 'additionalFieldMapping'"></AdditionalFieldMapping>
        </div>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark" style="overflow:auto;">
          <div class="appFormFooter__right" v-if="additionalFieldsData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="additionalFieldsData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="additionalFieldsData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="addOptionModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Add option </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="addOptionModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <form ref="addOptionForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Option</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <input type="text" class="input--full" v-model="optionValue" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="addOptionModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addOptionConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="moveUpModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Move field? </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="moveUpModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          Are you sure want to move the field <b> {{metafieldName}} </b> up?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="moveUpModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="moveUpConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="moveDownModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Move field? </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="moveDownModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          Are you sure want to move the field <b> {{metafieldName}} </b> down?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="moveDownModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="moveDownConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="removeConfirmModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Delete additional field? </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="removeConfirmModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          Are you sure you want to delete the field {{metafieldName}}?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="removeConfirmModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="removeMetaFieldConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="addFieldModal">
      <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
        <div class="jazeModal__header pb-0">
          <div class="jazeHeader jazeHeader--centered">
            <div class="jazeHeader__title"> Add New Additional Field </div>
            <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="addFieldModal=false">
              <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
              </g>
            </svg>
          </div>
        </div>
        <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
          <div class="col-6">
            <strong>{{errorMessage}}</strong>
          </div>
        </div>
        <div class="jazeModal__main" style="overflow: auto;">
          <form ref="createMetaFieldForm" id="createMetaFieldForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
            <div class="jazeModalContent pb-1">
              <div class="container container--horizontal-cozy container--vertical-comfy">
                <div class="row">
                  <div class="col jazeFormGroup">
                    <div class="appRow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline"> Name </label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="metaFieldName" required oninvalid="setCustomValidity(' ')" oninput="setCustomValidity('')" />
                      </div>
                    </div>
                    <div class="appRow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline">Type</label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <select class="input--full lineHeight--normal without-standard-validation" name="metaFieldType" v-model="metaFieldType" @change="fieldTypeChange">
                          <option value="" disabled> Choose Type </option>
                          <option value="string"> Text Box </option>
                          <option value="date"> Date </option>
                          <option value="drop_down"> Drop Down </option>
                        </select>
                      </div>
                    </div>
                    <div class="appRow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline"> Mandatory </label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <input type="checkbox" name="metaFieldIsMandatory" />
                      </div>
                    </div>
                    <div class="appRow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline"> Disabled </label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <input type="checkbox" name="metaFieldIsDisabled" />
                      </div>
                    </div>
                    <div class="appRow" v-if="stringDiv">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline"> Default value </label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="metaFieldDefaultValue" />
                      </div>
                    </div>
                    <div class="appRow" v-if="dateDiv">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline"> Default value </label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <VueCtkDateTimePicker v-model="defaultDate" name="metaFieldDefaultDateValue" :autoClose="true" :noLabel="true" />
                      </div>
                    </div>
                    <div v-if="dropdownDiv">
                      <div class="appRow" v-for="(field, index) in inputfields" :key="index">
                        <div class="col--sm4">
                          <label class="appLabel appLabel--boxInline"> Options </label>
                        </div>
                        <div class="col--sm8 appFormGroup appFormGroup--cozy">
                          <input type="text" class="input--full" v-model="field.myInputs" />
                        </div>
                      </div>
                      <div class="floatRight">
                        <button class="jazeButton jazeButton--link jazeButton--noWrap mb-2" type="button" @click="addDropdownOption">
                          <div> <span class="jaze-icon--plus"></span> ADD OPTION </div>
                        </button><br>
                        <button class="jazeButton jazeButton--link jazeButton--noWrap" type="button" @click="removeDropdownOption">
                          <div> <span class="jaze-icon--plus"></span> DELETE OPTION </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="jazeModal__footer">
          <div class="jazeButtonGroup jazeButtonGroup--cozy">
            <button class="jazeButton jazeButton--secondary" type="button" @click="addFieldModal=false">Cancel</button>
            <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addAdditionalField">Yes</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="additionalFieldsData.edition =='isp'">Allow/Deny sub-zones to change user fields settings</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change user fields settings</label> &nbsp;
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxAdditional" id="switchcheckboxAdditional" :checked="additionalFieldsData.subAccountsCanNotChangeAdditionalFieldsValues == 'Yes' ? true : false">
                          <label for="switchcheckboxAdditional"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="additionalFieldsData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change the user fields settings.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change the user fields settings.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="additionalFieldsData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'additionalFields'" :modalType="'subAccountsOnly'"></PushToSubZone>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import moment from "moment";
import VueCtkDateTimePicker from "jaze-ctk-date-time-picker";
import AdditionalFieldRules from "@/components/Settings/AdditionalFieldRules.vue";
import AdditionalFieldMapping from "@/components/Settings/AdditionalFieldMapping.vue";
import { uselayoutStore } from '@/store/layoutstore'

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore)
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  // const additionalFieldsData = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const removeConfirmModal = ref( false )
  const addFieldModal = ref( false )
  const editNameShow = ref( [] )
  const editName = ref( [] )
  const editDisabledShow = ref( [] )
  const editDisabled = ref( [] )
  const editDisabledConvert = ref( [] )
  const editMandatoryShow = ref( [] )
  const editMandatory = ref( [] )
  const editMandatoryConvert = ref( [] )
  const editDefaultValShow = ref( [] )
  const editDefaultVal = ref( [] )
  const editDefaultDropdown = ref( [] )
  const addOptionModal = ref( false )
  const metafieldIda = ref( "" )
  const metafieldName = ref( "" )
  const optionValue = ref( "" )
  const moveUpModal = ref( false )
  const moveDownModal = ref( false )
  const categoryName = ref( "" )
  const metaFieldType = ref( "" )
  const selectedTab = ref( "additionalField" )
  const dateDiv = ref( false )
  const dropdownDiv = ref( false )
  const stringDiv = ref( false )
  const defaultDate = ref( new Date() )
  const inputfields = ref( [
          {
            constmyInputs: "",
          },
        ] )
  const subZoneModalShow = ref( false )
  const selectedAccVal = ref( null )
  const subAccOptions = ref( [
          {
            id: "selectAll",
            label: "Select All",
            children: [],
          },
        ] )
  const switchcheckboxAdditional = ref( false )
  const pushModal = ref( false )
  const selected = ref( [] )
  // const subAccounts = ref( [] )

  const settingsStore = usesettingsStore()
  const { additionalFieldsData } = storeToRefs(settingsStore)
  const additionalFieldsGetter = computed(() => additionalFieldsData.value)

  onMounted(() => {
    addlFieldsSettings();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  const addlFieldsSettings = () => {
    if (Object.keys(additionalFieldsGetter.value).length == 0) {
      // $store.dispatch("fetchAdditionalFieldSettings");
      settingsStore.fetchAdditionalFieldSettings();
    } else {
      additionalFieldsData.value = additionalFieldsGetter.value;
      metaFieldNameValuesSet();
    }
  }
  const metaFieldNameValuesSet = () => {
    if (
      additionalFieldsData.value.metaFieldNameValues != undefined &&
      Object.keys(additionalFieldsData.value.metaFieldNameValues).length != 0
    ) {
      for (var i = 0; i < additionalFieldsData.value.categories.length; i++) {
        var category = additionalFieldsData.value.categories[i];
        if (
          additionalFieldsData.value.metaFieldNameValues[category].length != 0
        ) {
          for (
            var j = 0;
            j <
            additionalFieldsData.value.metaFieldNameValues[category].length;
            j++
          ) {
            var field =
              additionalFieldsData.value.metaFieldNameValues[category][j];
            editNameShow.value[j] = false;
            editDisabledShow.value[j] = false;
            editMandatoryShow.value[j] = false;
            editName.value[j] = field.MetaFieldName.name;
            if (field.MetaFieldName.is_disabled == "yes") {
              editDisabledConvert.value[j] = "Yes";
            } else if (field.MetaFieldName.is_disabled == "no") {
              editDisabledConvert.value[j] = "No";
            }
            editDisabled.value[j] = field.MetaFieldName.is_disabled;
            if (field.MetaFieldName.is_mandatory == "yes") {
              editMandatoryConvert.value[j] = "Yes";
            } else if (field.MetaFieldName.is_mandatory == "no") {
              editMandatoryConvert.value[j] = "No";
            }
            editMandatory.value[j] = field.MetaFieldName.is_mandatory;
            if (field.MetaFieldName.type == "drop_down") {
              var value = JSON.parse(field.MetaFieldName.value);
              editDefaultValShow.value[j] = [];
              editDefaultDropdown.value[j] = [];
              var n = 0;
              var value1 = {};
              for (const [key, values] of Object.entries(value)) {
                editDefaultValShow.value[j][n] = false;
                editDefaultDropdown.value[j][n] = values;
                value1[n] = values;
                n++;
              }
              value = value1;
            } else {
              var value = field.MetaFieldName.default_value_id;
              editDefaultValShow.value[j] = false;
            }
            editDefaultVal.value[j] = value;
          }
        }
      }
    }
  }
  const toggleNameTrue = (index) => {
    editNameShow.value.splice(index, 1, true);
  }
  const toggleNameFalse = (index) => {
    editNameShow.value.splice(index, 1, false);
  }
  const toggleDisabledTrue = (index) => {
    editDisabledShow.value.splice(index, 1, true);
  }
  const toggleDisabledFalse = (index) => {
    editDisabledShow.value.splice(index, 1, false);
  }
  const toggleMandatoryTrue = (index) => {
    editMandatoryShow.value.splice(index, 1, true);
  }
  const toggleMandatoryFalse = (index) => {
    editMandatoryShow.value.splice(index, 1, false);
  }
  const toggleValueTrue = (index, metaField, valueIndex = null) => {  
    if (metaField.type == "drop_down") {
      const newRow = editDefaultValShow.value[index].slice(0);
      newRow[valueIndex] = true;
      // editDefaultValShow.value[index] = newRow;
      editDefaultValShow.value[index][valueIndex] = true;
    } else {
      editDefaultValShow.value.splice(index, 1, true);
    }
  }
  const toggleValueFalse = (index, metaField, valueIndex = null) => {
    if (metaField.type == "drop_down") {
      const newRow = editDefaultValShow.value[index].slice(0);
      newRow[valueIndex] = false;
      editDefaultValShow.value[index] = newRow;
    } else {
      editDefaultValShow.value.splice(index, 1, false);
    }
  }
  const saveNamefn = (index, metaField) => {
    editNameShow.value.splice(index, 1, false);
    const postData = {};
    postData.name = metaField.id;
    postData.value = editName.value[index];
    postData.pk = metaField.id;
    postData.attribute = "name";
    editData(postData);
  }
  const deleteDropdownVal = (metaField, valueId) => {
    const postData = {};
    postData.metaFieldNameId = metaField.id;
    postData.optionId = valueId;
    // $store.dispatch("deleteMetaFieldOption", postData).then(
    settingsStore.deleteMetaFieldOption(postData).then(  
      (response) => {
        metaFieldNameValuesSet();
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const saveDisabledfn = (index, metaField) => {
    editDisabledShow.value.splice(index, 1, false);
    if (editDisabled.value[index] == "yes") {
      editDisabledConvert.value[index] = "Yes";
    } else if (editDisabled.value[index] == "no") {
      editDisabledConvert.value[index] = "No";
    }
    const postData = {};
    postData.name = metaField.id;
    postData.value = editDisabled.value[index];
    postData.pk = metaField.id;
    postData.attribute = "is_disabled";
    editData(postData);
  }
  const saveMandatoryfn = (index, metaField) => {
    editMandatoryShow.value.splice(index, 1, false);
    if (editMandatory.value[index] == "yes") {
      editMandatoryConvert.value[index] = "Yes";
    } else if (editMandatory.value[index] == "no") {
      editMandatoryConvert.value[index] = "No";
    }
    const postData = {};
    postData.name = metaField.id;
    postData.value = editMandatory.value[index];
    postData.pk = metaField.id;
    postData.attribute = "is_mandatory";
    editData(postData);
  }
  const saveValuefn = (index, metaField, valueIndex = null, valueId = null) => {
    const postData = {};
    postData.name = metaField.id;
    postData.pk = valueId;
    if (metaField.type == "drop_down") {
      const newRow = editDefaultValShow.value[index].slice(0);
      newRow[valueIndex] = false;
      editDefaultValShow.value[index] = newRow;
      postData.value = editDefaultDropdown.value[index][valueIndex];
      postData.attribute = "value";
    } else {
      editDefaultValShow.value.splice(index, 1, false);
      postData.value = editDefaultVal.value[index];
      postData.attribute = "default_value_id";
    }
    editData(postData);
  }
  const editData = (postData) => {
    // $store.dispatch("editAdditionalFieldsData", postData).then(
    settingsStore.editAdditionalFieldsData(postData).then(  
      (response) => {
        settingsStore.fetchAdditionalFieldSettings();
        successtoaster.value = true;
        successMessage.value = "Successfully Added";
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const addOption = (metafieldId) => {
    optionValue.value = "";
    addOptionModal.value = true;
    metafieldIda.value = metafieldId;
  }
  const addOptionConfirm = () => {
    const postData = {};
    postData.metaFieldNameId = metafieldIda.value;
    postData.optionValue = optionValue.value;
    // $store.dispatch("addMetaFieldOption", postData).then(
    settingsStore.addMetaFieldOption(postData).then(  
      (response) => {
        metaFieldNameValuesSet();
        addOptionModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const moveFieldUp = (metafield) => {
    moveUpModal.value = true;
    metafieldIda.value = metafield.MetaFieldName.id;
    metafieldName.value = metafield.MetaFieldName.name;
    categoryName.value = metafield.MetaFieldName.forwhat;
  }
  const moveUpConfirm = () => {
    const postData = {};
    postData.metaFieldId = metafieldIda.value;
    postData.forwhat = categoryName.value;
    // $store.dispatch("moveMetaFieldUp", postData).then(
    settingsStore.moveMetaFieldUp(postData).then(  
      (response) => {
        metaFieldNameValuesSet();
        moveUpModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const moveFieldDown = (metafield) => {
    moveDownModal.value = true;
    metafieldIda.value = metafield.MetaFieldName.id;
    metafieldName.value = metafield.MetaFieldName.name;
    categoryName.value = metafield.MetaFieldName.forwhat;
  }
  const moveDownConfirm = () => {
    const postData = {};
    postData.metaFieldId = metafieldIda.value;
    postData.forwhat = categoryName.value;
    // $store.dispatch("moveMetaFieldDown", postData).then(
    settingsStore.moveMetaFieldDown(postData).then(  
      (response) => {
        metaFieldNameValuesSet();
        moveDownModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const removeMetaData = (metafield) => {
    removeConfirmModal.value = true;
    metafieldName.value = metafield.MetaFieldName.name;
    metafieldIda.value = metafield.MetaFieldName.id;
  }
  const removeMetaFieldConfirm = () => {
    var data = {
      metaFieldId: metafieldIda.value,
    };
    // $store.dispatch("deleteMetaFieldSubmit", data).then(
    settingsStore.deleteMetaFieldSubmit(data).then(  
      (response) => {
        metaFieldNameValuesSet();
        removeConfirmModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        removeConfirmModal.value = false;
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const fieldTypeChange = () => {
    if (metaFieldType.value == "string") {
      dateDiv.value = false;
      dropdownDiv.value = false;
      stringDiv.value = true;
    } else if (metaFieldType.value == "date") {
      stringDiv.value = false;
      dropdownDiv.value = false;
      dateDiv.value = true;
    } else if (metaFieldType.value == "drop_down") {
      stringDiv.value = false;
      dateDiv.value = false;
      dropdownDiv.value = true;
    }
  }
  const addDropdownOption = () => {
    inputfields.value.push({ myInputs: "" });
  }
  const removeDropdownOption = () => {
    inputfields.value.pop();
  }
  const checkForm = (postData) => {
    if (postData.metaFieldName == "") {
      errorMessage.value = "Name is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.metaFieldType == undefined || postData.metaFieldType == "") {
      errorMessage.value = "Type is Required";
      errorShow.value = true;
      return "error";
    }
    if (metaFieldType.value == "drop_down" && dropdownDiv.value == true) {
      var stat = "";
      for (var i = 0; i < postData.myInputs.length; i++) {
        if (postData.myInputs[i] == "") {
          stat = "empty";
          break;
        }
      }
      if (stat == "empty") {
        errorMessage.value = "Option is Required";
        errorShow.value = true;
        return "error";
      }
    }
  }
  const addAdditionalField = (e) => {
    e.preventDefault();
    successtoaster.value = false;
    errortoaster.value = false;
    var form = document.getElementById('createMetaFieldForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    if (metaFieldType.value == "drop_down" && dropdownDiv.value == true) {
      var fieldvalues = [];
      for (var i = 0; i < inputfields.value.length; i++) {
        fieldvalues.push(inputfields.value[i].myInputs);
      }
      postData.myInputs = fieldvalues;
    }
    if (postData.metaFieldDefaultValue == undefined) {
      postData.metaFieldDefaultValue = "";
    }
    if (postData.metaFieldDefaultDateValue == undefined) {
      postData.metaFieldDefaultDateValue = "";
    }
    var status = checkForm(postData);
    if (status == "error") {
      formClass.value = "is-form-validation-visible";
      return;
    }
    errorShow.value = false;
    // $store.dispatch("createMetaFieldFormSubmit", postData).then(
    settingsStore.createMetaFieldFormSubmit(postData).then(
      (response) => {
        metaFieldNameValuesSet();
        addFieldModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      (error) => {
        addFieldModal.value = false;
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          5000
        );
        console.log(error);
      }
    );
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxAdditional.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeAdditionalFieldsValues",
      type: "additionalFields",
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      (response) => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const onclickclose = () => {
    pushModal.value = false;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

  watchEffect(() => {
    additionalFieldsData.value = additionalFieldsGetter.value;
    metaFieldNameValuesSet();
    defaultDate.value = moment(defaultDate.value).format("YYYY-MM-DD H:mm:ss");
    inputfields.value.push({ myInputs: "" });
    if (
      additionalFieldsData.value.accountsArr != undefined &&
      additionalFieldsData.value.accountsArr.length != 0
    ) {
      for (let i = 0; i < additionalFieldsData.value.accountsArr.length; i++) {
        var deactivated = additionalFieldsData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
        subAccOptions.value[0].children.push({
          id: additionalFieldsData.value.accountsArr[i].id,
          label: additionalFieldsData.value.accountsArr[i].name + deactivated,
        });
      }
    }
    if (
      additionalFieldsData.value
        .subAccountsCanNotChangeAdditionalFieldsValues == "Yes"
    ) {
      switchcheckboxAdditional.value = true;
    }
  })

</script>
